
#root {
  height: 100vh;
  background-color: #F2F2F2;
}

.App {
  text-align: center;
}

.content-wrap {
  min-height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.display-linebreak {
  white-space: pre-line;
}

.closeIcon {
  cursor: pointer;
}