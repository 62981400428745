.signInPanel {
  background-color: #ffffff;
  max-height: 80vh;
  position: fixed;
  top: 10%;
  right: 33%;
  overflow-y: hidden;
  z-index: 3;
}

.signInForm {
  align-items: center;
  display: flex;
  align-content: center;
  flex-direction: column;
}

.logo {
  color: #ff0000;
  font-size: 3.3rem;
  text-shadow: 2px 2.5px 4px rgba(0, 0, 0, 0.25);
  display: grid;
  justify-content: center;
  letter-spacing: 2.5px;
}
